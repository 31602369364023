<template>
  <div
    class="rounded-lg"
    :class="noMargin ? '' : 'mt-3'"
    >
    <template
      v-if="posts.length"
      >
      <template
        v-for="post, i in posts"
        >
        <div
          class="mb-2"
          :key="post.id"
          >
          <card
            v-if="post.__typename == 'Post'"
            :idx="i"
            :postId="post.id"
            ></card>
          <div
            v-else
            >
            <recommendations
              v-if="posts[i - 1].__typename != post.__typename"
              :content="post"
              :typename="post[0].__typename"
              :head="post[0].__typename == 'Product' ? 'Productos para tí' : 'Partners para tí'"
              ></recommendations>
          </div>

        </div>
      </template>
    </template>

    <template
      v-if="recommended.length && !explore"
      >
      <div
        class="caption pb-3 fill-width text-center" 
        >
        <v-divider
          class="mb-3"
          ></v-divider>

        <v-card
          flat
          disabled
          class="mb-3 rounded-lg"
          height="250"
          >
          <v-card-text
            class="fill-height d-flex flex-column align-center justify-center"
            >
            <v-icon
              size="75"
              color="secondary"
              >
              mdi-post-outline
            </v-icon>
            <div
              class="mt-3 text-subtitle-2 text-center primary--text"
              >
              ¡Parece que ya viste todas tus publicaciones disponibles! Aquí tienes una pequeña selección de publicaciones recomendadas.
            </div>
          </v-card-text>
        </v-card>

        Publicaciones recomendadas
      </div>
    </template>

    <template
      v-for="post, i in recommended"
      >
      <div
        :key="post.id"
        class="mb-3"
        >
        <card
          v-if="post.__typename == 'Post'"
          :idx="i"
          :postId="post.id"
          ></card>
        <div
          v-else
          >
          <recommendations
            v-if="(recommended[i - 1] || {}).__typename != post.__typename"
            :content="post"
            :typename="post[0].__typename"
            :head="post[0].__typename == 'Product' ? 'Productos para tí' : 'Partners para tí'"
            ></recommendations>
        </div>
      </div>
    </template>

    <div
      v-intersect="intersection"
      class="text-center"
      >
    </div>

    <template
      v-if="!posts.length && !recommended.length"
      >
      <v-card
        flat
        class="rounded-lg"
        height="250"
        disabled
        >
        <v-card-text
          class="fill-height d-flex flex-column align-center justify-center"
          >
          <v-icon
            size="75"
            color="secondary"
            >
            mdi-post-outline
          </v-icon>
          <div
            class="mt-3 text-subtitle-2 text-center primary--text"
            >
            ¡Parece que aún no hay publicaciones para ver aquí!
          </div>
        </v-card-text>
      </v-card>
    </template>
  </div>
</template>

<script>
import { Posts } from '@/graphql/queries/posts'
import { RecommendedProducts } from '@/graphql/queries/products'
import { RecommendedProfiles } from '@/graphql/queries/profiles'

const Card = () => import('@/components/posts/Card')
const Recommendations = () => import('@/components/recommendations/Recommendations')

export default {
  data: () => ({
    posts: [],
    recommended: [],
    end: null,
    bottomEnd: false,
    loading: false,
    firstLoad: true,
    page: 0,
  }),

  props: {
    profileId: {
      type: String | Number,
      required: false,
      default: null
    },
    feed: {
      type: Boolean,
      required: false,
      default: false
    },
    explore: {
      type: Boolean,
      required: false,
      default: false
    },
    noMargin: {
      type: Boolean,
      required: false,
      default: false
    },
  },

  watch: {
    page () {
      this.fetchPosts()
      if (this.feed) {
        this.fetchRecommendations()
      }
    }
  },

  components: { Card, Recommendations },

  methods: {
    fetchRecommendations () {
      var queries = [RecommendedProducts, RecommendedProfiles]
      var query = queries[Math.round(Math.random() * (queries.length - 1))];

      this.$apollo.query({
        query: query,
        fetchPolicy: 'no-cache'
      }).then( res => {
        if (this.end) {
          if (query == RecommendedProducts) {
            this.recommended.push(res.data.recommendedProducts)
          } else {
            this.recommended.push(res.data.recommendedProfiles)
          }
        } else {
          if (query == RecommendedProducts) {
            this.posts.push(res.data.recommendedProducts)
          } else {
            this.posts.push(res.data.recommendedProfiles)
          }
        }
      })
    },

    fetchPosts () {
      this.end ||= this.explore
      this.loading = true
      this.$apollo.query({
        query: Posts,
        variables: {
          profileId: this.profileId,
          page: this.page,
          recommended: this.end,
        },
        fetchPolicy: 'cache-first'
      }).then ( res => {
        if (this.end) {
          this.recommended = [
            ...this.recommended,
            ...res.data.posts
          ]
        } else {
          this.posts = [
            ...this.posts,
            ...res.data.posts
          ]
        }

        if (!res.data.posts.length && (this.end || this.profileId)) {
          this.bottomEnd = true
        } else if (!res.data.posts.length && !this.end) {
          this.end = true
          this.page = 1
          this.fetchPosts()
        }

        if (res.data.posts.length < 5 && !this.end && this.feed) {
          this.end = true
          this.page = 1
          this.fetchPosts()
        }

        setTimeout( () => {
          this.loading = false
        }, 400)
      })
    },

    intersection (val) {
      if (this.firstLoad && this.page == 1) return this.firstLoad = false
      if (!this.loading && !this.bottomEnd) {
        this.page ++
      }
    }
  }
}
</script>
