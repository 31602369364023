import gql from 'graphql-tag'

import { Comment as CommentFragment } from '@/graphql/fragments'

export const Posts = gql`
  query posts($profileId: ID, $page: ID, $recommended: Boolean) {
    posts(profileId: $profileId, page: $page, recommended: $recommended) {
      id
    }
  } 
`

export const Post = gql`
  query post($id: ID!) {
    post(id: $id) {
      id
      body
      elapsed
      postType
      commentIds
      likedBy
      product {
        id
        name
        displayPrice
        images {
          thumbUrl
        }
      }
      store {
        name
        information {
          fullAddress
        }
        profile {
          slug
          picture {
            url
          }
        }
      }
      profile {
        id
        name
        slug
        profilableType
        picture {
          url
          thumbUrl
        }
      }
      pictures {
        url
        filter
      }
    }
  } 
`

export const Like = gql`
  query like($id: ID!) {
    like(id: $id) {
      id
      likeableId
      likeableType
    }
  } 
`

export const Comment = gql`
  query comment($id: ID!) {
    comment(id: $id) {
      ...${CommentFragment}
      replies {
        ...${CommentFragment}
      }
    }
  } 
`

export const Comments = gql`
  query comments($ids: [ID!]!) {
    comments(ids: $ids) {
      ...${CommentFragment}
      children {
        ...${CommentFragment}
      }
    }
  } 
`
